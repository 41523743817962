import React from "react";
import PropTypes from "prop-types";

function Banner({ banner }) {
  return (
    <div>
      <span
        dangerouslySetInnerHTML={{
          __html: banner,
        }}
      />
    </div>
  );
}

Banner.prototype = {
  banner: PropTypes.string,
};

export default Banner;
