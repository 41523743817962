import useSWR from "swr";
import Api from "../../services/Api";

function useGetAllBanners(page_location, entity_id) {
  const store_hash = process.env.AD_STORE_HASH;
  const getKey = () => {
    // Below url has type query params that is only for SWR to identify url uniquely.
    // That is not a valid query params for Bigcommerce.
    return `${process.env.NETLIFY_API_BASE_ADURL}/bc/api?type=banners`;
  };

  const { data, error } = useSWR(() => getKey(), getAllBanners);

  const isLoading = !data && !error;
  const banners = data ? data : [];

  const bannerData =
    banners.length &&
    banners?.filter((banner) => {
      // Filter for home page...
      if (page_location === "home_page") {
        return banner.item_id === "0" && Number(banner.visible) !== 0;
      }

      // Filter for category page...
      if (page_location === "category_page") {
        return (
          banner.item_id === entity_id.toString() &&
          Number(banner.visible) !== 0
        );
      }

      // Filter for brand page...
      if (page_location === "brand_page") {
        return (
          banner.item_id === entity_id.toString() &&
          Number(banner.visible) !== 0
        );
      }
    });

  const top_banner =
    bannerData?.length &&
    bannerData.filter((banner) => {
      return banner?.location === "top";
    });

  const bottom_banner =
    bannerData?.length &&
    bannerData.filter((banner) => {
      return banner.location === "bottom";
    });

  return {
    top_banner:
      top_banner?.[0]?.content?.replace(
        "%%GLOBAL_CdnStorePath%%",
        `https://cdn11.bigcommerce.com/s-${store_hash}`
      ) || "",
    bottom_banner:
      bottom_banner?.[0]?.content?.replace(
        "%%GLOBAL_CdnStorePath%%",
        `https://cdn11.bigcommerce.com/s-${store_hash}`
      ) || "",
    isLoading,
  };
}

async function getAllBanners(url) {
  const response = await Api.post(url, {
    url: "v2/banners",
    query_params: {},
    method: "get",
    body: {},
  });
  return response.data;
}

export default useGetAllBanners;
