import useSWR from "swr";
import Api from "../../services/Api";

function useGetSubCategoryByParentId(parent_id) {
  const getKey = () => {
    // Below url has type query params that is only for SWR to identify url uniquely.
    // That is not a valid query params for Bigcommerce.
    return parent_id
      ? `${process.env.NETLIFY_API_BASE_ADURL}bc/api?id=${parent_id}`
      : null;
  };

  const { data, error } = useSWR(() => getKey(), getSubCategories);

  const isLoading = !data && !error;
  const subCategories = data?.data || [];

  async function getSubCategories(url) {
    const body = {
      url: "v3/catalog/categories",
      query_params: {
        page: 1,
        parent_id: parent_id,
        include_fields: "custom_url,name,id",
      },
      method: "get",
      body: {},
    };
    const response = await Api.post(url, body);
    return response;
  }
  return {
    subCategories,
    isLoading,
  };
}

export default useGetSubCategoryByParentId;
