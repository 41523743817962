import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function SubCategories({ subCategories }) {
  return subCategories.length ? (
    <div>
      <h4 className="headline">RELATED COLLECTION</h4>
      <ul className="sub-category-list flex-wrap">
        {subCategories.map((item) => (
          <li>
            <Link to={`${item.custom_url.url}`}>{`${item.name}`}</Link>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}

SubCategories.prototype = {
  subCategories: PropTypes.array,
};

export default SubCategories;
